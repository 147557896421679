// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "prodapi",
            "endpoint": "https://hhpqexikoj.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "devapi",
            "endpoint": "https://zgboez9pjb.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "pdf2img-20191111190418-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d3mj1nbhrzlbf6.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-west-2:76b6425f-72de-4d0c-a06a-2be1fb9c5259",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mskejWQCq",
    "aws_user_pools_web_client_id": "2oqpk3o8mpv7s1ai8t773jql10",
    "oauth": {}
};


export default awsmobile;
